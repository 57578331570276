<template>
    <HeaderSection v-bind="headerProps" />
    <div class="content-wrap">
		<div class="entry entry-page">
			<div class="entry-content">
				<div class="accomodation-listing">
                    <Accomodation :accomodation="item" v-for="(item, index) in accomodations" :key="index" />
                </div>
            </div>
        </div>
        <div class="entry-copyright static pl-0 flex justify-between">
            <nav class="social-menu">
                <ul>
                    <li class="menu-item"><a :href="socialProps.facebook_link" target="_blank"><span class="mdi"></span><span class="screen-reader-text">Facebook Page</span></a></li>
                    <li class="menu-item"><a :href="socialProps.twitter_link" target="_blank"><span class="mdi"></span><span class="screen-reader-text">Twitter Profile</span></a></li>
                    <li class="menu-item"><a :href="socialProps.instagram_link" target="_blank"><span class="mdi"></span><span class="screen-reader-text">Instagram Profile</span></a></li>
                </ul>
            </nav>
            <CopyRight v-bind="footerProps"/>
        </div>
    </div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import Accomodation from '../../organisms/Accomodation/Accomodation.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection,
        Accomodation,
        CopyRight
    },
    props: {
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        accomodations: {
            type: Array,
            default: () => [],
        }
    },
    beforeCreate() {
        this.$nextTick().then(() => document.body.classList.add('full-content'))
    },
    data() {
        return {
            footerProps
        }
    }
}
</script>
<style>
    @media (min-width: 1200px) {
        .full-content .fullpage #site-menu { background-color: #fff; }
    }
</style>
