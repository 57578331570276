<template>
  <PackagesTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :accomodations="accomodations"
  />
</template>

<script>
import PackagesTemplate from "@/components/templates/Packages/Packages";
import {
  headerProps,
  socialProps,
  accomodation,
} from "@/components/constants.js";
export default {
  components: {
    PackagesTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      accomodations: accomodation.list,
    };
  },
};
</script>

<style scoped></style>
