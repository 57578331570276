<template>
    <article class="entry-accomodation">
        <div class="entry-media">
            <a href="single-accomodation.html">
                <img :src="accomodation.image" width="1380" height="500" alt="">
            </a>
        </div>
        <AccomodationText v-bind="accomodation" />
    </article>
</template>

<script>
import AccomodationText from "../../molecules/AccomodationText/AccomodationText.vue";

export default {
  components: {
    AccomodationText
  },
  props: {
    accomodation: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
